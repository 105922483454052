











































































import { Component, Prop } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { formatIsoDateTime } from "@/filters";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import mFiles, { MFilesDocumentDetails } from "@/api/mFiles";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import AttachmentRow from "@/components/attachments/AttachmentRow.vue";
import AttachmentList from "@/components/attachments/AttachmentList.vue";
import DocumentAttachments from "@/components/dossier/DocumentAttachments.vue";
import { AxiosResponse } from "axios";
import Page from "@/components/layouts/Page.vue";
import TheHeader from "@/components/TheHeader.vue";

@Component({
  components: {
    AttachmentList,
    Loader,
    DocumentLayout,
    AttachmentRow,
    DocumentAttachments,
    Page,
    TheHeader,
  },
  methods: { formatIsoDateTime },
})
@Translatable("dossier")
export default class DocumentDetails extends TranslatableComponent {
  @Prop({ default: "" })
  private accountId!: string;

  @Prop({ default: "" })
  private documentId!: number;

  @Prop({ default: "" })
  private vault!: string;

  private dossierName: string = "";

  private documentDetails: MFilesDocumentDetails | null = null;

  private isLoading: boolean = true;

  mounted() {
    mFiles
      .getDocumentDetails(this.accountId, this.vault, this.documentId)
      .then((response: AxiosResponse<MFilesDocumentDetails>) => {
        this.documentDetails = response.data;
        this.dossierName = this.documentDetails.name;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_view_value("can_not_get_document_details")
        );
        this.documentDetails = null;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
